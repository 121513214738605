"use client";
import { useEffect, useState } from "react";
import ProjectCategory from "../../molecules/ProjectCategory";
import ProjectCard from "../../molecules/ProjectCard";

import VisibilitySensor from "react-visibility-sensor";

interface ProjectsProps {
  categories: Array<{
    title?: string;
    image?: string;
    imageAlt?: string;
    href?: string;
  }>;
}

const Projects = (props: ProjectsProps) => {
  const { categories } = props;

  const [imagesShownArray, setImagesShownArray] = useState(
    Array(categories.length).fill(false)
  );

  const imageVisibleChange = (index: any, isVisible: any) => {
    if (isVisible) {
      setImagesShownArray((currentImagesShownArray: any) => {
        currentImagesShownArray[index] = true;
        return [...currentImagesShownArray];
      });
    }
  };

  useEffect(() => {
    console.log("categories.length === 2 =>", categories.length === 2);
  }, [categories]);

  return (
    <div
      className={`w-full flex max-[600px]:!flex-col flex-wrap gap-2 items-stretch ${
        categories.length === 2
          ? "justify-center !flex-row items-center max-[600px]:!flex-col"
          : "justify-start max-[600px]:!flex-col"
      }`}
    >
      {categories ? (
        categories.map((category, idx) => (
          <VisibilitySensor
            key={idx}
            partialVisibility={true}
            offset={{ bottom: 80 }}
            onChange={(isVisible: any) => imageVisibleChange(idx, isVisible)}
          >
            <ProjectCard
              show={imagesShownArray[idx]}
              {...category}
              title={category.title}
              image={category.image}
              imageAlt={category.imageAlt}
              href={category.href}
              fixedWidth={500}
              fixedHeight={300}
              undersix={categories.length === 6}
              hasonlytwo={categories.length === 2}
            />
          </VisibilitySensor>
        ))
      ) : (
        <p>Leider sind hier noch keine Projekte hochgeladen worden.</p>
      )}
    </div>
  );
};

export default Projects;
