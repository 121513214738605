"use client";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ProjectCategories from "../../organisms/ProjectCategories";
import { NavigationLinks } from "../../molecules";
import Projects from "../../organisms/Projects";
import Einfamilienhaueser from "../../../assets/Hangunten.jpg";
import Mehrfamilienhaueser from "../../../assets/mehrfamilienhaueser/20230819_102227.jpg";
import BauenImBestand from "../../../assets/DetailGartenansichtrechts.jpg";
import Gewerbebau from "../../../assets/Foddis1.jpeg";
import Entwicklung from "../../../assets/P1.png";
import { Helmet } from "react-helmet";

interface ProjectsPageProps {}

const ProjectsPage = (props: ProjectsPageProps) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Projekte | Freier Architekt & Gutachter für Schäden an Gebäuden
          Dipl.-Ing. Andreas Stuber – Expertise in Planung, Realisation und
          Bauberatung
        </title>
        <meta
          name="description"
          content="Tauchen Sie ein in die beeindruckende Welt der Projekte von Dipl.-Ing. Andreas Stuber. Entdecken Sie eine breite Palette von Architekturprojekten, von Wohn- und Gewerbebauten über städtebauliche Konzepte bis hin zu kreativen Nutzungsumwandlungen. Erfahren Sie mehr über unsere Kooperationen mit SCIO Architects in Paracin, Serbia, und Energieberatung Eichler in Neu-Ulm, Deutschland. Erleben Sie präzise Planung, innovative Gestaltung und nachhaltige Energiekonzepte. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und setzen Sie Ihre eigene Vision mit uns um."
        />
      </Helmet>
      <section
        data-aos="fade-up"
        data-aos-duration="500"
        id="office"
        className="px-20 relative flex justify-center items-center flex-col h-max"
      >
        <div
          className="flex items-center gap-3"
          data-aos="fade-down"
          data-aos-duration="500"
        >
          <h1 className="text-[#000] text-[30px]">projekte</h1>
        </div>
      </section>
      <section
        data-aos="fade-up"
        data-aos-duration="500"
        id="categories"
        className="px-20 relative flex h-max w-full my-5"
      >
        <ProjectCategories
          categories={[
            {
              title: "einfamilienhäuser",
              image: Einfamilienhaueser,
              imageAlt: "",
              href: "einfamilienhaeuser",
            },
            {
              title: "mehrfamilienhäuser",
              image: Mehrfamilienhaueser,
              imageAlt: "",
              href: "mehrfamilienhaeuser",
            },
            {
              title: "bauen im bestand",
              image: BauenImBestand,
              imageAlt: "",
              href: "bauen im bestand",
            },
            {
              title: "gewerbebau",
              image: Gewerbebau,
              imageAlt: "",
              href: "gewerbebau",
            },
            {
              title: "entwicklung",
              image: Entwicklung,
              imageAlt: "",
              href: "entwicklung",
            },
          ]}
        />
      </section>
    </div>
  );
};

export default ProjectsPage;
