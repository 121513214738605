"use client";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

interface ServicesPagerops {}

const ServicesPage = (props: ServicesPagerops) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Leistungen | Freier Architekt & Gutachter für Schäden an Gebäuden
          Dipl.-Ing. Andreas Stuber – Expertise in Planung, Realisation und
          Bauberatung
        </title>
        <meta
          name="description"
          content="Tauchen Sie ein in die beeindruckende Welt der Projekte von Dipl.-Ing. Andreas Stuber. Entdecken Sie eine breite Palette von Architekturprojekten, von Wohn- und Gewerbebauten über städtebauliche Konzepte bis hin zu kreativen Nutzungsumwandlungen. Erfahren Sie mehr über unsere Kooperationen mit SCIO Architects in Paracin, Serbia, und Energieberatung Eichler in Neu-Ulm, Deutschland. Erleben Sie präzise Planung, innovative Gestaltung und nachhaltige Energiekonzepte. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und setzen Sie Ihre eigene Vision mit uns um."
        />
      </Helmet>
      <section
        id="services"
        className={`px-20 max-[600px]:!text-center flex flex-col justify-start items-center !text-left`}
      >
        <div className="flex items-center justify-center gap-3 mt-5 w-full">
          <h1 className="text-[#000] text-[30px]">leistungen</h1>
        </div>
        <div className="relative flex h-max w-full my-4">
          <div className="w-[100%] flex justify-start items-center flex-col ">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex justify-start items-center gap-3"
            >
              <h5 className="text-[16px]">
                planungen und realisation wohn und gewerbebau
              </h5>
            </div>
          </div>
        </div>
        <div className="relative flex h-max w-full my-4">
          <div className="w-[100%] flex justify-left items-center flex-col">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex justify-start items-center gap-3"
            >
              <h5 className="text-[16px]">
                machbarkeitsanalyse für umbau und sanierungen im bestand und
                neubau
              </h5>
            </div>
          </div>
        </div>
        <div className="relative flex h-max w-full my-4">
          <div className="w-[100%] flex justify-start items-center flex-col">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex justify-start items-center gap-3"
            >
              <h5 className="text-[16px]">städtebauliche konzepte</h5>
            </div>
          </div>
        </div>
        <div className="relative flex h-max w-full my-4">
          <div className="w-[100%] flex justify-start items-center flex-col ">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex justify-start items-center gap-3"
            >
              <h5 className="text-[16px]">
                nutzungsänderungen für gewerbe und wohnbau
              </h5>
            </div>
          </div>
        </div>
        <div className="relative flex h-max w-full my-4">
          <div className="w-[100%] flex justify-start items-center flex-col ">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex justify-start items-center gap-3"
            >
              <h5 className="text-[16px]">beratung bem immobilienkauf</h5>
            </div>
          </div>
        </div>
        <div className="relative flex h-max w-full my-4">
          <div className="w-[100%] flex justify-start items-center flex-col ">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex justify-start items-center gap-3"
            >
              <h5 className="text-[16px]">bauzustandsbewertung</h5>
            </div>
          </div>
        </div>
        <div className="relative flex h-max w-full my-4">
          <div className="w-[100%] flex justify-start items-center flex-col ">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex justify-start items-center gap-3"
            >
              <h5 className="text-[16px]">bauüberwachung</h5>
            </div>
            <Link
              data-aos="fade-right"
              data-aos-duration="500"
              to={"/contact"}
              className="mt-10 underline"
            >
              kontakt
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesPage;
