import Pic1 from "../assets/IMG_0568.jpg";
import Pic2 from "../assets/JPEG-Bild-4A4F-9214-B3-0.jpeg";
import Pic3 from "../assets/JPEG-Bild-4272-AF28-E9-0.jpeg";
import Pic4 from "../assets/JPEG-Bild-4537-AFC3-D9-0.jpeg";

const no = [
  {
    id: 1,
    alt: "Gutachter Bild 1",
    url: Pic1,
  },
  {
    id: 2,
    alt: "Gutachter Bild 2",
    url: Pic2,
  },
  {
    id: 3,
    alt: "Gutachter Bild 3",
    url: Pic3,
  },
  {
    id: 4,
    alt: "Gutachter Bild 4",
    url: Pic4,
  },
];
export default no;
