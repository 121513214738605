"use client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faMobileAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const ContactPage = (props: any) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`${isScrolled ? "mt-56" : ""}`}>
      <Helmet>
        <title>
          Kontakt | Freier Architekt & Gutachter für Schäden an Gebäuden
          Dipl.-Ing. Andreas Stuber – Expertise in Planung, Realisation und
          Bauberatung
        </title>
        <meta
          name="description"
          content="Tauchen Sie ein in die beeindruckende Welt der Projekte von Dipl.-Ing. Andreas Stuber. Entdecken Sie eine breite Palette von Architekturprojekten, von Wohn- und Gewerbebauten über städtebauliche Konzepte bis hin zu kreativen Nutzungsumwandlungen. Erfahren Sie mehr über unsere Kooperationen mit SCIO Architects in Paracin, Serbia, und Energieberatung Eichler in Neu-Ulm, Deutschland. Erleben Sie präzise Planung, innovative Gestaltung und nachhaltige Energiekonzepte. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und setzen Sie Ihre eigene Vision mit uns um."
        />
      </Helmet>
      <section className="max-[600px]:px-3 px-20">
        <div className="relative flex h-max w-full mb-40 text-center justify-center">
          <div className="max-[600px]:w-[100%] w-[50%] flex justify-center items-left flex-col">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex items-center justify-center gap-3"
            >
              <h1 className="text-[#000] text-[30px]">kontakt</h1>
            </div>
            <p data-aos="fade-up" data-aos-duration="500">
              wir freuen uns über dein interesse! bei fragen, anregungen oder
              anfragen stehen wir gerne zur verfügung.
            </p>
            <FontAwesomeIcon icon={faEnvelope} className="my-4" />
            <a href="mailto:ararchitekt-stuber@t-online.de">
              architekt-stuber@t-online.de
            </a>
            <FontAwesomeIcon icon={faPhone} className="my-4" />
            <a href="tel:+4971629476133">07162 9476133</a>
            <FontAwesomeIcon icon={faMobileAlt} className="my-4" />
            <a href="tel:+491797895265">0179 7895265</a>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="my-4" />
            <p>Wittumgärten 21 73333 Gingen</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
