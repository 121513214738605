// Importing images
import GöppingenImage1 from "../assets/gewerbebaugöppingen/20230727_111816.jpg";
import GöppingenImage2 from "../assets/gewerbebaugöppingen/20230727_111809.jpg";

import BodenseekreisHotelImage1 from "../assets/gewerbebaubodenkreishotel/Bild1.png";
import BodenseekreisHotelImage2 from "../assets/gewerbebaubodenkreishotel/Bildschirmfoto1.png";
import BodenseekreisHotelImage3 from "../assets/gewerbebaubodenkreishotel/Bildschirmfoto2.png";
import BodenseekreisHotelGrundriss from "../assets/gewerbebaubodenkreishotel/Grundriss Erdgeschoss.png";
import BodenseekreisHotelVorher from "../assets/gewerbebaubodenkreishotel/vorher.jpg";

import NeuUlmImage1 from "../assets/bauenimbestandautoservice/Foddis.jpeg";
import NeuUlmImage2 from "../assets/bauenimbestandautoservice/Foddis2.jpeg";

import HeidenheimImage from "../assets/20230830_161352.jpeg";

const gewerbebau = [
  {
    id: 41,
    title: "Zentrale für Intensivpflegekräfte und Anbau einer Gewerbehalle",
    place: "Geislingen, Kreis Göppingen",
    duration: "Baubeginn 2020 Fertigstellung 2022",
    description: "",
    type: "Gewerblicher Bauherr",
    image: GöppingenImage1,
    imageAlt: "",
    href: "41",
    gallery: [{ url: GöppingenImage1 }, { url: GöppingenImage2 }],
  },
  {
    id: 42,
    title: "Hotel Merian",
    place: "Friedrichshafen, Bodenseekreis",
    duration: "Baubeginn 2020 Fertigstellung 2022",
    description:
      "Umbau eines ehemaligen Dialysezentrums zu einem Hotel mit 22 Betten",
    type: "Gewerblicher Bauherr",
    image: BodenseekreisHotelImage1,
    imageAlt: "",
    href: "42",
    gallery: [
      { url: BodenseekreisHotelImage1 },
      { url: BodenseekreisHotelImage2 },
      { url: BodenseekreisHotelImage3 },
      { url: BodenseekreisHotelGrundriss },
      { url: BodenseekreisHotelVorher, before: true },
    ],
  },
  {
    id: 43,
    title: "Erweiterung des Auto Service Betriebs",
    place: "Neu Ulm",
    duration: "Fertigstellung 2016",
    description:
      "Erweiterung des Auto Service Betriebs um eine Lackierhalle sowie Wartungshalle",
    type: "Gewerblicher Bauherr",
    image: NeuUlmImage1,
    imageAlt: "",
    href: "43",
    gallery: [{ url: NeuUlmImage1 }, { url: NeuUlmImage2 }],
  },
  {
    id: 44,
    title:
      "Umnutzung einer leerstehenden gewerbeeinheit zur planung und vertrieb von solaranlagen mit lager",
    place: "Heidenheim, Ostalbkreis",
    duration: "Fertigstellung 2022",
    description:
      "Umnutzung einer leerstehenden Gewerbeeinheit zur Planung und Vertrieb von Solaranlagen mit Lager",
    type: "Gewerblicher Bauherr",
    image: HeidenheimImage,
    imageAlt: "",
    href: "44",
    gallery: [{ url: HeidenheimImage }],
  },
];

export { gewerbebau };
