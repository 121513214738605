"use client";
import { useEffect, useState } from "react";
import MainBg from "../../../assets/mainbackgroundandreas.png";
import { Helmet } from "react-helmet";

interface HomePageProps {}

const HomePage = (props: HomePageProps) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Home | Freier Architekt & Gutachter für Schäden an Gebäuden Dipl.-Ing.
          Andreas Stuber – Expertise in Planung, Realisation und Bauberatung
        </title>
        <meta
          name="description"
          content="Tauchen Sie ein in die beeindruckende Welt der Projekte von Dipl.-Ing. Andreas Stuber. Entdecken Sie eine breite Palette von Architekturprojekten, von Wohn- und Gewerbebauten über städtebauliche Konzepte bis hin zu kreativen Nutzungsumwandlungen. Erfahren Sie mehr über unsere Kooperationen mit SCIO Architects in Paracin, Serbia, und Energieberatung Eichler in Neu-Ulm, Deutschland. Erleben Sie präzise Planung, innovative Gestaltung und nachhaltige Energiekonzepte. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und setzen Sie Ihre eigene Vision mit uns um."
        />
      </Helmet>
      <section
        id="home"
        className={`max-[600px]:px-2 px-20 relative flex justify-center items-center h-max max-[600px]:mb-10 mb-40 bg-white`}
      >
        <div
          className={`relative min-h-[610px] max-[600px]:min-h-[410px] bg-cover bg-center flex items-center w-full`}
          style={{ backgroundImage: `url(${MainBg})` }}
        >
          <div
            id="overlay"
            className="w-full h-full absolute z-[1] opacity-60"
          ></div>
          <div id="content" className="z-10 max-w-[686px] px-20">
            <h1
              data-aos="fade-down"
              data-aos-duration="500"
              className="hidden text-[#fff] text-[66px] leading-[5rem]"
            >
              Architektur aus Deutschland
            </h1>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
