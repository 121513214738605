"use client";
import { ILinks } from "../../../interfaces";
import NavigationLink from "../../atoms/NavigationLink";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface NavigationLinksProps {
  links: Array<ILinks>;
  color?: string;
  fontBold?: boolean;
  underline?: boolean;
  col?: boolean;
  serif?: boolean;
  lowercase?: boolean;
  customClass?: string;
  menuOpen?: boolean;
  setMenuOpen?: Dispatch<SetStateAction<boolean>>;
}

const NavigationLinksMobile = (props: NavigationLinksProps) => {
  const {
    links,
    color = "text-[#000]",
    fontBold = true,
    underline = true,
    col = false,
    serif = false,
    lowercase = false,
    customClass = "",
    menuOpen,
    setMenuOpen,
  } = props;
  const path = useLocation();
  const [activeLink, setActiveLink] = useState<ILinks>(links[0]);

  useEffect(() => {
    const activeLink = links.find((link) => link.path === path.pathname);
    if (!activeLink) return;
    setActiveLink(activeLink);
  }, [path]);

  return (
    <div
      className={`bg-white w-[100vw] h-[100vh] ${
        menuOpen ? "!flex" : "!hidden"
      } absolute z-[999] top-0 justify-center items-center flex-col`}
    >
      <FontAwesomeIcon
        icon={faTimes}
        className="text-[24px] absolute top-10 right-10 cursor-pointer"
        onClick={() => setMenuOpen && setMenuOpen(false)}
      />
      <ul className={`${customClass} flex-col`}>
        {links &&
          links.map((link, idx) => (
            <li
              key={idx}
              className={`py-5 !text-center ${serif && "font-serif"}`}
            >
              <NavigationLink
                isMobile={true}
                setMenuOpen={setMenuOpen}
                lowercase={lowercase}
                color={color}
                fontBold={fontBold}
                underline={underline}
                active={link === activeLink}
                text={link.name}
                href={link.path}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default NavigationLinksMobile;
