import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from "./components/pages";
import LayoutWrapper from "./components/wapper/LayoutWrapper";
import ServicesPage from "./components/pages/ServicesPage";
import OfficeTwoPage from "./components/pages/OfficeTwoPage";
import OfficePage from "./components/pages/OfficePage";
import ContactPage from "./components/pages/ContactPage/ContactPage";
import ProjectCategories from "./components/organisms/ProjectCategories";
import ProjectsPage from "./components/pages/ProjectsPage";
import CategoryPage from "./components/pages/CategoryPage";
import ProjectPage from "./components/pages/ProjectPage";
import Imprint from "./components/pages/Imprint/page";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <LayoutWrapper>
              <HomePage />
            </LayoutWrapper>
          }
        />
        <Route
          path="/projectcategories"
          element={
            <LayoutWrapper>
              <ProjectsPage />
            </LayoutWrapper>
          }
        />
        <Route
          path="/projectcategories/:category"
          element={
            <LayoutWrapper>
              <CategoryPage />
            </LayoutWrapper>
          }
        />
        <Route
          path="/projectcategories/:category/:projectid"
          element={
            <LayoutWrapper>
              <ProjectPage />
            </LayoutWrapper>
          }
        />
        <Route
          path="/services"
          element={
            <LayoutWrapper>
              <ServicesPage />
            </LayoutWrapper>
          }
        />
        <Route
          path="/office"
          element={
            <LayoutWrapper>
              <OfficePage />
            </LayoutWrapper>
          }
        />
        <Route
          path="/gutachter"
          element={
            <LayoutWrapper>
              <OfficeTwoPage />
            </LayoutWrapper>
          }
        />
        <Route
          path="/contact"
          element={
            <LayoutWrapper>
              <ContactPage />
            </LayoutWrapper>
          }
        />
        <Route
          path="/imprint"
          element={
            <LayoutWrapper>
              <Imprint />
            </LayoutWrapper>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
