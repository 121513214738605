'use client';
import {ILinks} from '../../../interfaces';
import NavigationLink from '../../atoms/NavigationLink';
import {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';

interface NavigationLinksProps {
  links: Array<ILinks>;
  color?: string;
  fontBold?: boolean;
  underline?: boolean;
  col?: boolean;
  serif?: boolean;
  lowercase?: boolean;
  customClass?: string;
}

const NavigationLinks = (props: NavigationLinksProps) => {
  const {links, color = 'text-[#000]', fontBold = true, underline = true, col = false, serif = false, lowercase = false, customClass = ""} = props;
  const path = useLocation();
  const [activeLink, setActiveLink] = useState<ILinks>(links[0]);

  useEffect(() => {
    const activeLink = links.find((link) => link.path === path.pathname);
    if (!activeLink) return;
    setActiveLink(activeLink);
  }, [path]);

  return (
    <ul className={`${customClass} gap-[20px] flex ${col ? '' : 'mt-4'} ${col ? 'flex flex-col' : ''}`}>
      {links &&
        links.map((link, idx) => (
          <li key={idx} className={`max-[600px]:!pr-0 ${serif && 'font-serif'}`}>
            <NavigationLink lowercase={lowercase} color={color} fontBold={fontBold} underline={underline} active={link === activeLink} text={link.name} href={link.path} />
          </li>
        ))}
    </ul>
  );
};

export default NavigationLinks;
