import Tegelberg from "../assets/Tegelberg.jpeg";
import SubTegelberg1 from "../assets/mehrfamilienhaustegelberg/bildersatz.png";
import Salach from "../assets/mehrfamilienhausalach/AnsichtNordKopie.jpg";
import SubSalach from "../assets/mehrfamilienhausalach/AnsichtNordKopie.jpg";
import SubSalach2 from "../assets/mehrfamilienhausalach/AnsichtSuedKopie.jpg";
import Erbach from "../assets/20230821_163235.jpg";
import SubErbach1 from "../assets/MehrfamilienhausErbach/20230821_163235.jpg";
import SubErbach2 from "../assets/MehrfamilienhausErbach/Erbach3D107.jpg";
import SubErbach3 from "../assets/MehrfamilienhausErbach/erg.jpeg";
import SubErbach4 from "../assets/MehrfamilienhausErbach/vorher.png";
import Geislingen from "../assets/mehrfamilien14/GeislingenB4Kopie.jpg";
import SubGeislingen1 from "../assets/mehrfamilien14/GeislingenB4Kopie.jpg";
import SubGeislingen2 from "../assets/mehrfamilien14/GeislingenB5Kopie.jpg";
import SubGeislingen3 from "../assets/FabrikstrKopie.jpg";
import Göppingen from "../assets/20230819_102227.jpg";
import GöppingenSub from "../assets/mehrfamilienhausgeislingen4/20230819_102227.jpg";
import GöppingenSub2 from "../assets/mehrfamilienhausgeislingen4/20230819_102430.jpg";
import Tegelberg2 from "../assets/20231205_104842.jpg";
import TegelBerg2Sub1 from "../assets/mehrfamilienhaustegelberg2/20231205_104842.jpg";
import TegelBerg2Sub2 from "../assets/mehrfamilienhaustegelberg2/tegelberg2.jpg";

const mehrfamilienhauser = [
  {
    id: 21,
    title: "Mehrfamilienhaus 8 Wohneinheiten, Tegelberg - Kreis Göppingen",
    place: "Tegelberg, Geislingen, Kreis Göppingen",
    duration: "Baubeginn 2020, Fertigstellung voraussichtlich 2024",
    description:
      "Mehrfamilienhaus 8 Wohneinheiten Baubeginn 2020, Fertigstellung voraussichtlich 2024",
    type: "Immobilienunternehmen",
    image: Tegelberg,
    imageAlt: "",
    href: "21",
    gallery: [{ url: Tegelberg }, { url: SubTegelberg1 }],
  },
  {
    id: 22,
    title: "Mehrfamilienhaus 22 Wohneinheiten, Salach - Kreis Göppingen",
    place: "Salach, Kreis Göppingen",
    duration: "Baubeginn voraussichtlich 2024/2025",
    description: "",
    type: "Immobilienunternehmen",
    image: Salach,
    imageAlt: "",
    href: "22",
    gallery: [{ url: SubSalach }, { url: SubSalach2 }],
  },
  {
    id: 23,
    title: "Mehrfamilienhaus 4 Wohneinheiten, Erbach",
    place: "Erbach, Alb-Donau-Kreis",
    duration: "Baubeginn 2022, Fertigstellung 2023",
    description: "",
    type: "Privater Bauherr",
    image: Erbach,
    imageAlt: "",
    href: "23",
    gallery: [
      { url: SubErbach1 },
      { url: SubErbach2 },
      { url: SubErbach3 },
      { url: SubErbach4, before: true },
    ],
  },
  {
    id: 24,
    title: "Terrassensiedlung 14 Appartements, Geislingen - Kreis Göppingen",
    place: "Geislingen, Kreis Göppingen",
    duration: "Baubeginn Dezember 2023, Fertigstellung voraussichtlich 2025",
    description: "",
    type: "Immobilienunternehmen",
    image: Geislingen,
    imageAlt: "",
    href: "24",
    gallery: [
      { url: SubGeislingen1 },
      { url: SubGeislingen2 },
      { url: SubGeislingen3 },
    ],
  },
  {
    id: 25,
    title: "Mehrfamilienhaus 4 Wohneinheiten, Geislingen - Kreis Göppingen",
    place: "Geislingen, Kreis Göppingen",
    duration: "Baubeginn 2021 Fertigstellung 2023",
    description: "",
    type: "Privater Bauherr",
    image: Göppingen,
    imageAlt: "",
    href: "25",
    gallery: [{ url: GöppingenSub }, { url: GöppingenSub2 }],
  },
  {
    id: 26,
    title: "Mehrfamilienhaus Tegelberg, Tegelberg - Kreis Göppingen",
    place: "Tegelberg, Geislingen, Kreis Göppingen",
    duration: "Baubeginn 2023, Fertigstellung voraussichtlich 2024",
    description: "",
    type: "Privater Bauherr",
    image: Tegelberg2,
    imageAlt: "",
    href: "26",
    gallery: [{ url: TegelBerg2Sub1 }, { url: TegelBerg2Sub2 }],
  },
];

export { mehrfamilienhauser };
