import {NavigationLinks} from '../../molecules';

const Footer = (props: any) => {
  const {} = props;

  return (
    <footer id='footer' className='p-5 bg-[#676767] w-full justify-center mt-auto bottom-0'>
      <div className='flex max-[600px]:flex-col max-[600px]:!text-center justify-center w-full'>
        <div id='company' className='max-[600px]:w-[100%] w-[33.33%]'>
          <h5 className='text-[#fff] max-[600px]:w-[100%] text-[25px] mb-4'>architektur</h5>
          <NavigationLinks
            links={[
              {path: '/office', name: 'büro'},
              {path: '/projectcategories', name: 'projekte'},
              {path: '/services', name: 'leistungen'},
            ]}
            col={true}
            color='text-[#fff]'
            fontBold={false}
            underline={false}
            customClass='max-[600px]:p-0'
          />
        </div>
        <div id='infomration' className='max-[600px]:w-[100%] max-[600px]:mt-5 w-[33.33%]'>
          <h5 className='text-[#fff] text-[25px] mb-4'>informationen</h5>
          <NavigationLinks
            links={[
              {path: '/imprint', name: 'impressum'},
              {path: '/contact', name: 'kontakt'},
            ]}
            col={true}
            color='text-[#fff]'
            fontBold={false}
            underline={false}
          />
        </div>
      </div>
      <div id='copyright' className='text-[#fff] w-full text-center mt-5'>
        Copyright 2024
      </div>
    </footer>
  );
};

export default Footer;
