import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faMobileAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const Imprint = (props: any) => {
  return (
    <section className="max-[600px]:px-3 px-20">
      <Helmet>
        <title>
          Impressum | Freier Architekt & Gutachter für Schäden an Gebäuden
          Dipl.-Ing. Andreas Stuber – Expertise in Planung, Realisation und
          Bauberatung
        </title>
        <meta
          name="description"
          content="Tauchen Sie ein in die beeindruckende Welt der Projekte von Dipl.-Ing. Andreas Stuber. Entdecken Sie eine breite Palette von Architekturprojekten, von Wohn- und Gewerbebauten über städtebauliche Konzepte bis hin zu kreativen Nutzungsumwandlungen. Erfahren Sie mehr über unsere Kooperationen mit SCIO Architects in Paracin, Serbia, und Energieberatung Eichler in Neu-Ulm, Deutschland. Erleben Sie präzise Planung, innovative Gestaltung und nachhaltige Energiekonzepte. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und setzen Sie Ihre eigene Vision mit uns um."
        />
      </Helmet>
      <div className="relative flex h-max w-full mb-40 text-center justify-center">
        <div className="max-[600px]:w-[100%] max-[600px]:px-3 w-[50%] flex justify-center items-left flex-col px-20">
          <div
            data-aos="fade-left"
            data-aos-duration="500"
            className="flex items-center justify-center gap-3"
          >
            <div className="w-[60px] h-[2px] bg-[#000]"></div>
            <h1 className="text-[#000] max-[600px]:text-[26px] text-[46px]">
              Impressum
            </h1>
            <div className="w-[60px] h-[2px] bg-[#000]"></div>
          </div>
          <p className="max-[600px]:text-[16px] max-[600px]:w-[100%] text-[26px] text-black font-bold mt-10">
            Freier Arhictekt und Sachverständiger für Gebäudeschäden
          </p>
          <p className="max-[600px]:text-[16px] text-[20px]">
            Dipl. Ing. Andreas Stuber
          </p>
          <FontAwesomeIcon icon={faPhone} className="my-4" />
          <p>07162 9476133</p>
          <FontAwesomeIcon icon={faMobileAlt} className="my-4" />
          <p>0179 7895265</p>
          <FontAwesomeIcon icon={faMapMarkerAlt} className="my-4" />
          <p>Wittumgärten 21 73333 Gingen</p>
          <p className="text-[18px] text-black font-bold mt-5">Bankdaten:</p>
          <p>Postbank Stuttgart BLZ 600 100 70</p>
          <p>Konto 208 303 707</p>
          <p className="text-[18px] mt-5 text-black font-bold">Steuernummer:</p>
          <p>62356/28248</p>
        </div>
      </div>
    </section>
  );
};

export default Imprint;
