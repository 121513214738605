"use client";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import Portrait from "../../../assets/Portait1.png";
import Mitglied from "../../../assets/Mitgliederlogo_t.png";
import ScioLog from "../../../assets/SCIOLogo.png";
import Energieberatung from "../../../assets/energieberatung-eichler-logo.png";
import { Helmet } from "react-helmet";

interface OfficePageProps {}

const OfficePage = (props: OfficePageProps) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Büro | Freier Architekt & Gutachter für Schäden an Gebäuden Dipl.-Ing.
          Andreas Stuber – Expertise in Planung, Realisation und Bauberatung
        </title>
        <meta
          name="description"
          content="Tauchen Sie ein in die beeindruckende Welt der Projekte von Dipl.-Ing. Andreas Stuber. Entdecken Sie eine breite Palette von Architekturprojekten, von Wohn- und Gewerbebauten über städtebauliche Konzepte bis hin zu kreativen Nutzungsumwandlungen. Erfahren Sie mehr über unsere Kooperationen mit SCIO Architects in Paracin, Serbia, und Energieberatung Eichler in Neu-Ulm, Deutschland. Erleben Sie präzise Planung, innovative Gestaltung und nachhaltige Energiekonzepte. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und setzen Sie Ihre eigene Vision mit uns um."
        />
      </Helmet>
      <section
        id="about"
        className="max-[600px]:!px-2 px-20 relative flex flex-col justify-center items-center h-max w-full"
      >
        <div className="flex items-center gap-3 mb-10 mt-2">
          <h1 className="text-[#000] text-[30px]">büro</h1>
        </div>
        <div className="flex w-full justify-center max-[600px]:!flex-col">
          <div
            data-aos="fade-right"
            data-aos-duration="500"
            className="relative flex justify-center max-[600px]:!w-[100%] w-[50%] h-[50%]"
          >
            <img
              className="max-[600px]:!w-[100%] w-[56%] h-full object-contain"
              src={Portrait}
              alt="Architekt Bild"
              width={602}
              height={275}
            />
          </div>
          <div className="max-[600px]:!w-[100%] max-[600px]:text-center w-[50%] flex h-[100%] justify-center items-left flex-col">
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="max-[600px]:!text-center max-[600px]:!mt-3 flex items-center"
            >
              <h2 className="max-[600px]:!w-[100%] text-[26px]">
                dipl. ing. andreas stuber
              </h2>
            </div>
            <ul
              data-aos="fade-right"
              data-aos-duration="500"
              className="max-[600px]:!list-none list-disc pl-4"
            >
              <li>freier architekt seit 2000</li>
              <li>
                gutachter für schäden an gebäuden <br></br>zertifikat hochschule
                für technik konstanz
              </li>
              <li className="max-[600px]:!items-center flex flex-col">
                <img
                  data-aos="fade-down"
                  data-aos-duration="500"
                  className="h-full object-cover"
                  src={Mitglied}
                  alt="Architekt Bild"
                  width={249}
                  height={81}
                />
                <p
                  data-aos="fade-up"
                  data-aos-duration="500"
                  className="text-[#000]"
                >
                  mitgliedsnummer: 057201
                </p>
              </li>
            </ul>
            <a
              data-aos="fade-right"
              data-aos-duration="500"
              href={"#services"}
              className="mt-10 underline cursor-pointer"
            >
              kontakt
            </a>
          </div>
        </div>
      </section>
      <section
        id="about"
        className="relative flex justify-center items-start max-[600px]:flex-col flex-row h-max w-full mt-20 mb-10 max-[600px]:px-3 px-20"
      >
        <div className="flex flex-col max-[600px]:w-[100%] w-[50%] !text-left items-center justify-center">
          <div className="flex flex-col max-[600px]:items-center">
            <p className="max-[600px]:text-center text-[#000] text-[18px]">
              planungskoorperation
            </p>
            <a
              className="mt-2 !h-[39.13px] !w-[137px] cursor-pointer"
              href={"https://nikolabrankovic.com/"}
              target="_blank"
            >
              <img
                className="h-full object-contain"
                src={ScioLog}
                alt="Architekt Bild"
                width={337}
                height={72.25}
              />
            </a>
            <p>vojvode misica 36, 35250 paracin, serbia</p>
            <a href="+381 62 344 287">+381 62 344 287</a>
          </div>
        </div>
        <div className="flex flex-col max-[600px]:mt-10 max-[600px]:items-center max-[600px]:w-[100%] w-[50%] !text-left items-start justify-center">
          <div className="flex flex-col max-[600px]:items-center">
            <p className="max-[600px]:text-center text-[#000] text-[18px]">
              energieberatung und energiekonzepte
            </p>
            <a
              className="mt-2 !h-[49.13px] !w-[137px] flex items-start cursor-pointer"
              href={"https://energieberatung-eichler.de/"}
              target="_blank"
            >
              <img
                className="h-full object-contain"
                src={Energieberatung}
                alt="energieberatung-eichler-logo"
                width={337}
                height={72.25}
              />
            </a>
            <p className="max-[600px]:text-center">
              marlene-dietrich-straße 5, 89231 neu-ulm, deutschland
            </p>
            <a href="+49 731 85075950">+49 731 85075950 </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OfficePage;
