'use client';
import {useState} from 'react';
import ProjectCategory from '../../molecules/ProjectCategory';
import VisibilitySensor from 'react-visibility-sensor';

interface ProjectCategoriesProps {
  categories: Array<{title?: string; image?: string; imageAlt?: string; href?: string}>;
}

const ProjectCategories = (props: ProjectCategoriesProps) => {
  const {categories} = props;

  const [imagesShownArray, setImagesShownArray] = useState(Array(categories.length).fill(false));

  const imageVisibleChange = (index: any, isVisible: any) => {
    if (isVisible) {
      setImagesShownArray((currentImagesShownArray) => {
        currentImagesShownArray[index] = true;
        return [...currentImagesShownArray];
      });
    }
  };

  return (
    <div className={`flex flex-wrap max-[600px]:!flex-col gap-2 items-stretch justify-start`}>
      {categories &&
        categories.map((category, idx) => (
          <VisibilitySensor key={idx} partialVisibility={true} offset={{bottom: 80}} onChange={(isVisible: any) => imageVisibleChange(idx, isVisible)}>
            <ProjectCategory show={imagesShownArray[idx]} {...category} title={category.title} image={category.image} imageAlt={category.imageAlt} href={category.href} fixedWidth={500} fixedHeight={300} undersix={categories.length === 6} />
          </VisibilitySensor>
        ))}
    </div>
  );
};

export default ProjectCategories;
