"use client";
import { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  faArrowLeft,
  faArrowRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import no from "../../../utils/no";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

interface OfficeTwoPageProps {}

const OfficeTwoPage = (props: OfficeTwoPageProps) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [image, setImage] = useState<any | null>(null);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [canSwiperNext, setCanSwipeNext] = useState<boolean>(true);
  const [canSwiperPrev, setCanSwipePrev] = useState<boolean>(false);
  const [hover, setHover] = useState(false);
  const [showSwipe, setShowSwipe] = useState<boolean>(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (no && no.length > 1) {
      setShowSwipe(true);
    }
  }, [no]);

  return (
    <div>
      <Helmet>
        <title>
          Gutachter | Freier Architekt & Gutachter für Schäden an Gebäuden
          Dipl.-Ing. Andreas Stuber – Expertise in Planung, Realisation und
          Bauberatung
        </title>
        <meta
          name="description"
          content="Tauchen Sie ein in die beeindruckende Welt der Projekte von Dipl.-Ing. Andreas Stuber. Entdecken Sie eine breite Palette von Architekturprojekten, von Wohn- und Gewerbebauten über städtebauliche Konzepte bis hin zu kreativen Nutzungsumwandlungen. Erfahren Sie mehr über unsere Kooperationen mit SCIO Architects in Paracin, Serbia, und Energieberatung Eichler in Neu-Ulm, Deutschland. Erleben Sie präzise Planung, innovative Gestaltung und nachhaltige Energiekonzepte. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und setzen Sie Ihre eigene Vision mit uns um."
        />
      </Helmet>
      <section
        id="about"
        className="max-[600px]:px-3 px-20 relative flex flex-col justify-center items-center h-max w-full mb-20"
      >
        <div className="flex items-center gap-3 mb-5 mt-5">
          <h1 className="text-[#000] text-[30px]">gutachter</h1>
        </div>
        <div className="relative flex flex-col justify-center items-center h-max w-full mt-2 mb-40">
          <div className="custom-container-as max-[600px]:flex max-[600px]:!flex-col">
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
              {no &&
                no.map((item: any, idx: number) => (
                  <div
                    key={idx}
                    className="relative w-full h-[150px] md:h-[250px] overflow-hidden"
                  >
                    <img
                      src={item.url}
                      alt={item.alt}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
            </div>
            <div className="flex justify-center items-center">
              <div
                id="description"
                className="flex max-[600px]:text-center text-left justify-center items-left flex-col text-[25px] mt-2"
              >
                <div
                  data-aos="fade-left"
                  data-aos-duration="500"
                  className="flex items-center"
                >
                  <h2 className="text-[26px] max-[600px]:w-[100%]">
                    dipl. ing. andreas stuber
                  </h2>
                </div>
                <ul
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className="max-[600px]:list-none list-disc pl-4"
                >
                  <li className="!text-[16px]">
                    gutachter für schäden an gebäuden <br></br>zertifikat
                    hochschule für technik konstanz
                  </li>
                </ul>
                <h2 className="!text-[20px] lowercase max-[600px]:text-center text-left max-[600px]:mt-10">
                  Leistungen
                </h2>
                <ul
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className="max-[600px]:list-none list-disc pl-4 !text-[16px]"
                >
                  <li>beratung bei immobilienkauf</li>
                  <li>schadensanalyse bei gebäudeschäden</li>
                  <li>sanierungskonzepte </li>
                  <li>baubegleitung</li>
                  <li>bauabnahme, gesamt oder einzelgewerke</li>
                  <li>baubegleitende qualitätskontrolle</li>
                  <li>prüfung der bauverträge</li>
                  <li>bauleitung</li>
                </ul>
                <Link
                  data-aos="fade-right"
                  data-aos-duration="500"
                  to={"/contact"}
                  className="mt-10 underline"
                >
                  kontakt
                </Link>
              </div>
            </div>
          </div>
          <ReactModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Image Modal"
            className="image-modal"
            overlayClassName="overlay"
          >
            <div className="modal-content relative">
              {image && (
                <img
                  src={image.url!}
                  alt={image && image.alt}
                  className="w-full h-auto object-cover"
                />
              )}{" "}
              <button
                className="z-[9999] absolute top-0 right-0"
                onClick={closeModal}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  color="#ffffff"
                  className="text-[40px]"
                />
              </button>
            </div>
          </ReactModal>
        </div>
      </section>
    </div>
  );
};

export default OfficeTwoPage;
