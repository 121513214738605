"use client";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ProjectCategories from "../../organisms/ProjectCategories";
import { NavigationLinks } from "../../molecules";
import { useParams } from "react-router-dom";

import Projects from "../../organisms/Projects";
import einfamilien from "../../../utils/einfamilienhauser";
import bauenimbestand from "../../../utils/bauenimbestand";
import { mehrfamilienhauser } from "../../../utils/mehrfamilienhauser";
import { entwicklung } from "../../../utils/entwicklung";
import { gewerbebau } from "../../../utils/gewerbebau";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

interface CategoryPageProps {
  category?: string;
  title?: string;
  projects?: any;
}

const generateTitle = (category: string) => {
  switch (category.toLowerCase()) {
    case "einfamilienhaeuser":
      return "Einfamilienhäuser";
    case "bauen%20im%20bestand":
      return "Bauen im Bestand";
    case "mehrfamilienhaeuser":
      return "Mehrfamilienhäuser";
    case "entwicklung":
      return "Entwicklung";
    case "gewerbebau":
      return "Gewerbebau";
    default:
      return "Projekte";
  }
};

const CategoryPage = (props: CategoryPageProps) => {
  const { category } = useParams<{ category: string }>();
  const [projects, setProjects] = useState<any>([]);
  const navigate = useNavigate();
  const title = generateTitle(category!);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getTitel = () => {
    console.log("category =>", category);
    switch (category?.toLowerCase()) {
      case "einfamilienhaeuser":
        return "Einfamilienhäuser";
      case "bauen im bestand":
        return "Bauen im Bestand";
      case "mehrfamilienhaeuser":
        return "Mehrfamilienhäuser";
      case "entwicklung":
        return "Entwicklung";
      case "gewerbebau":
        return "Gewerbebau";
    }
  };

  useEffect(() => {
    const addProjects = () => {
      switch (category!.toLowerCase()) {
        case "einfamilienhaeuser":
          setProjects(einfamilien);
          break;
        case "bauen im bestand":
          setProjects(bauenimbestand);
          break;
        case "mehrfamilienhaeuser":
          setProjects(mehrfamilienhauser);
          break;
        case "entwicklung":
          setProjects(entwicklung);
          break;
        case "gewerbebau":
          setProjects(gewerbebau);
          break;
        default:
          break;
      }
    };

    addProjects();
  }, []);
  return (
    <>
      <Helmet>
        <title>{`${title} | Freier Architekt & Gutachter für Schäden an Gebäuden Dipl.-Ing. Andreas Stuber – Expertise in Planung, Realisation und Bauberatung`}</title>
        <meta
          name="description"
          content="Tauchen Sie ein in die beeindruckende Welt der Projekte von Dipl.-Ing. Andreas Stuber. Entdecken Sie eine breite Palette von Architekturprojekten, von Wohn- und Gewerbebauten über städtebauliche Konzepte bis hin zu kreativen Nutzungsumwandlungen. Erfahren Sie mehr über unsere Kooperationen mit SCIO Architects in Paracin, Serbia, und Energieberatung Eichler in Neu-Ulm, Deutschland. Erleben Sie präzise Planung, innovative Gestaltung und nachhaltige Energiekonzepte. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und setzen Sie Ihre eigene Vision mit uns um."
        />
      </Helmet>
      <section
        data-aos="fade-up"
        data-aos-duration="500"
        id="office"
        className="px-20 relative flex justify-center items-center flex-col h-max"
      >
        <div
          className="flex items-center gap-3"
          data-aos="fade-down"
          data-aos-duration="500"
        >
          <h1 className="text-[#000] text-[30px]">
            {getTitel()?.toLocaleLowerCase()}
          </h1>
        </div>
        <div className="flex w-full justify-center items-center">
          <a
            onClick={() => navigate(-1)}
            className="hover:scale-110 transition duration-200 cursor-pointer mt-5"
          >
            <FontAwesomeIcon icon={faAngleLeft} className="mr-4" />
            zurück zu den rubriken
          </a>
        </div>
      </section>
      <section
        data-aos="fade-up"
        data-aos-duration="500"
        id="categories"
        className="px-20 relative flex h-max w-full my-5"
      >
        {projects.length ? (
          <Projects categories={projects} />
        ) : (
          <div className="flex flex-col justify-center items-center w-full">
            <p className="text-center w-full">
              Leider sind hier noch keine Projekte für die Rubrik "{category}"
              verfügbar
            </p>
            <a
              className="lowercase mt-4 text-black underline"
              onClick={() => navigate("/projectcategories")}
            >
              zu den Projekt Rubriken
            </a>
          </div>
        )}
      </section>
    </>
  );
};

export default CategoryPage;
