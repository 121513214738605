"use client";
import { useEffect, useRef, useState } from "react";
import einfamilien from "../../../utils/einfamilienhauser";
import bauenimbestand from "../../../utils/bauenimbestand";
import { mehrfamilienhauser } from "../../../utils/mehrfamilienhauser";
import { entwicklung } from "../../../utils/entwicklung";
import { gewerbebau } from "../../../utils/gewerbebau";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faArrowLeft,
  faArrowRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

interface ProjectPageProps {
  title?: string;
  id?: number;
}

const extractText = (pathname: string) => {
  const startIndex =
    pathname.indexOf("/projectcategories/") + "/projectcategories/".length;
  const endIndex = pathname.indexOf("/", startIndex);
  return pathname.substring(startIndex, endIndex);
};

const getTitle = (projectid: any) => {
  let title;
  const einfamilienhaus = einfamilien.find(
    (e) => e.id === Number(projectid)
  )?.title;
  const bau = bauenimbestand.find((e) => e.id === Number(projectid))?.title;
  const mehrfamilienhaus = mehrfamilienhauser.find(
    (e) => e.id === Number(projectid)
  )?.title;
  const entwicklungs = entwicklung.find(
    (e) => e.id === Number(projectid)
  )?.title;
  const gewerbebaus = gewerbebau.find((e) => e.id === Number(projectid))?.title;
  if (einfamilienhaus) title = einfamilienhaus;
  if (bau) title = bau;
  if (mehrfamilienhaus) title = mehrfamilienhaus;
  if (entwicklungs) title = entwicklungs;
  if (gewerbebaus) title = gewerbebaus;

  return title;
};

const ProjectPage = (props: ProjectPageProps) => {
  const { category, projectid } = useParams();
  const title = getTitle(projectid);

  const [projectData, setProjectData] = useState<any>(null);
  const [image, setImage] = useState<any | null>(null);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [canSwiperNext, setCanSwipeNext] = useState<boolean>(true);
  const [canSwiperPrev, setCanSwipePrev] = useState<boolean>(false);
  const [hover, setHover] = useState(false);
  const [showSwipe, setShowSwipe] = useState<boolean>(false);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const getRightText = () => {
    switch (category) {
      case "mehrfamilienhaeuser":
        return "mehrfamilienhäuser";
      case "entwicklung":
        return "entwicklungs";
      case "einfamilienhaeuser":
        return "einfamilienhäuser";
      case "bauen im bestand":
        return "bauen im bestand";
      case "gewerbebau":
        return "gewerbebau";
    }
  };

  useEffect(() => {
    const einfamilienhaus = einfamilien.find((e) => e.id === Number(projectid));
    const bau = bauenimbestand.find((e) => e.id === Number(projectid));
    const mehrfamilienhaus = mehrfamilienhauser.find(
      (e) => e.id === Number(projectid)
    );
    const entwicklungs = entwicklung.find((e) => e.id === Number(projectid));
    const gewerbebaus = gewerbebau.find((e) => e.id === Number(projectid));
    if (einfamilienhaus) setProjectData(einfamilienhaus);
    if (bau) setProjectData(bau);
    if (mehrfamilienhaus) setProjectData(mehrfamilienhaus);
    if (entwicklungs) setProjectData(entwicklungs);
    if (gewerbebaus) setProjectData(gewerbebaus);
  }, []);

  useEffect(() => {
    if (projectData)
      if (projectData.gallery.length > 1) {
        setShowSwipe(true);
      }
  }, [projectData]);

  useEffect(() => {
    console.log("useParams =>", projectid);
  }, [projectid]);

  return (
    <div>
      <Helmet>
        <title>{`${title} | Freier Architekt & Gutachter für Schäden an Gebäuden Dipl.-Ing. Andreas Stuber – Expertise in Planung, Realisation und Bauberatung`}</title>
        <meta
          name="description"
          content="Tauchen Sie ein in die beeindruckende Welt der Projekte von Dipl.-Ing. Andreas Stuber. Entdecken Sie eine breite Palette von Architekturprojekten, von Wohn- und Gewerbebauten über städtebauliche Konzepte bis hin zu kreativen Nutzungsumwandlungen. Erfahren Sie mehr über unsere Kooperationen mit SCIO Architects in Paracin, Serbia, und Energieberatung Eichler in Neu-Ulm, Deutschland. Erleben Sie präzise Planung, innovative Gestaltung und nachhaltige Energiekonzepte. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und setzen Sie Ihre eigene Vision mit uns um."
        />
      </Helmet>
      <section id={`${category}`} className={`px-20 max-[600px]:px-3`}>
        <div className="flex w-full justify-center items-center">
          <a
            onClick={() => navigate(-1)}
            className="hover:scale-110 transition duration-200 cursor-pointer mt-5"
          >
            <FontAwesomeIcon icon={faAngleLeft} className="mr-4" />
            zurück zu {"'"}
            {getRightText()}
            {"'"} projekten
          </a>
        </div>
        <div className="relative flex flex-col justify-center items-center h-max w-full mt-2 mb-40">
          <div
            data-aos="fade-left"
            data-aos-duration="500"
            className="max-[600px]:flex hidden mb-2"
          >
            <div
              className="items-left gap-3 mt-5"
              data-aos="fade-down"
              data-aos-duration="500"
            >
              <h5 className="text-[26px] max-[600px]:text-center text-left text-black">
                {projectData && projectData.title.toLowerCase()}
              </h5>
            </div>
          </div>
          <div className="custom-container-as max-[600px]:flex max-[600px]:flex-col">
            <Swiper
              onActiveIndexChange={(swiper: any) => {
                setCanSwipeNext(swiper.allowSlideNext);
                setCanSwipePrev(swiper.allowSlidePrev);

                setCanSwipeNext(swiper.isEnd ? false : true);
                setCanSwipePrev(swiper.isBeginning ? false : true);
              }}
              onSwiper={(swiper: any) => setSwiperInstance(swiper)}
              navigation={{ nextEl: nextRef.current, prevEl: prevRef.current }}
              pagination={{
                clickable: true,
              }}
              modules={[]}
              className="mySwiper"
            >
              {projectData &&
                projectData.gallery &&
                projectData.gallery.map((i: any, idx: number) => (
                  <SwiperSlide
                    key={idx}
                    className="relative h-auto !flex !justify-center !items-center"
                  >
                    <div
                      className={`relative max-h-[600px] h-[500px] bg-cover w-[80%] bg-center flex items-center cursor-pointer`}
                      onClick={() => {
                        setImage(i); // Set the clicked image URL
                        openModal(); // Open the modal
                      }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      <div
                        className={`!absolute z-[9999] top-0 right-0 bg-[#00000054] ${
                          hover
                            ? "flex transition duration-200"
                            : "hidden transition duration-200"
                        } justify-center items-center w-full h-full`}
                      >
                        <p className="text-white">
                          Klicke drauf um das Foto im Vollbild anzusehen
                        </p>
                      </div>
                      <img
                        src={i.url}
                        alt={projectData.title}
                        className="!object-contain h-full w-full"
                      />
                      {i.before && (
                        <div className="absolute block max-w-max z-[70] bottom-[19%] mr-auto ml-auto p-2 left-0 right-0 bg-black">
                          <p className="text-white text-[20px]">
                            Bestand vorher
                          </p>
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              {showSwipe && (
                <div className="flex justify-center items-center custom-prev !z-10 h-[100%]">
                  <div
                    id="prev"
                    onClick={() => canSwiperPrev && swiperInstance.slidePrev()!}
                    className={`${
                      canSwiperPrev ? "bg-[#676767]" : "bg-[#8d8d8d7b]"
                    } p-4 cursor-pointer mr-2`}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} color="#fff" />
                  </div>
                </div>
              )}
              {showSwipe && (
                <div className="flex justify-center items-center custom-next !z-10 h-[100%]">
                  <div
                    onClick={() => canSwiperNext && swiperInstance.slideNext()}
                    className={`${
                      canSwiperNext ? "bg-[#676767]" : "bg-[#8d8d8d7b]"
                    } p-4 cursor-pointer mr-2`}
                  >
                    <FontAwesomeIcon icon={faArrowRight} color="#fff" />
                  </div>
                </div>
              )}
            </Swiper>
            <div
              id="description"
              className="flex max-[600px]:text-center text-left justify-center items-left flex-col text-[25px] mt-2"
            >
              <div
                data-aos="fade-left"
                data-aos-duration="500"
                className="mb-2 max-[600px]:hidden flex"
              >
                <div
                  className="flex items-left gap-3"
                  data-aos="fade-down"
                  data-aos-duration="500"
                >
                  <h5 className="text-[26px] max-[600px]:text-center text-left text-black">
                    {projectData && projectData.title.toLowerCase()}
                  </h5>
                </div>
              </div>
              <ul className="max-[600px]:list-none list-disc pl-4">
                {projectData && projectData.place && (
                  <li
                    className="text-[18px]"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    {projectData.place.toLowerCase()}
                  </li>
                )}
                {projectData && projectData.duration && (
                  <li
                    className="text-[18px]"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    {projectData.duration.toLowerCase()}
                  </li>
                )}
                {projectData && projectData.description && (
                  <li
                    className="text-[18px]"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    {projectData && projectData.description.toLowerCase()}
                  </li>
                )}
                {projectData && projectData.type && (
                  <li
                    className="text-[18px]"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    {projectData && projectData.type.toLowerCase()}
                  </li>
                )}
              </ul>
              <Link
                data-aos="fade-right"
                data-aos-duration="500"
                to={"/contact"}
                className="mt-10 underline"
              >
                kontakt
              </Link>
            </div>
          </div>
          <ReactModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Image Modal"
            className="image-modal"
            overlayClassName="overlay"
          >
            <div className="modal-content relative">
              {projectData && image && image.before && (
                <div className="absolute block max-w-max z-[70] bottom-[0%] mr-auto ml-auto p-2 left-0 right-0 bg-black">
                  <p className="text-white text-[20px]">Bestand vorher</p>
                </div>
              )}
              {image && (
                <img src={image.url!} alt={projectData && projectData.title} />
              )}
              <button
                className="z-[9999] absolute top-0 right-0"
                onClick={closeModal}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  color="#ffffff"
                  className="text-[40px]"
                />
              </button>
            </div>
          </ReactModal>
        </div>
      </section>
    </div>
  );
};

export default ProjectPage;
