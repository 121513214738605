// Importing images
import GeislingenImage from "../assets/bauenimbestandgeislingen/20230819_103142.jpg";
import GeislingenVorher from "../assets/bauenimbestandgeislingen/vorher.jpeg";

import LeibiImage1 from "../assets/bauenimbestandleibi/AufstockungeinesWohnhauses,Leibi-Nersingen-8.jpg";
import LeibiImage2 from "../assets/bauenimbestandleibi/AufstockungeinesWohnhausesLeibi-Nersingen-3.jpg";
import LeibiVorher from "../assets/bauenimbestandleibi/Vorher-1.jpg";

import ForstenriedImage from "../assets/lance-anderson-QdAAasrZhdk-unsplash-604.png";
import ForstenriedVorher from "../assets/bauenimbestandforsten/Strassenansichtvorher.jpg";
import ForstenriedImage2 from "../assets/bauenimbestandforsten/Strassenansicht.jpg";

import GerlingenImage from "../assets/bauenimbestandgerlingen/Pers1.jpg";
import GerlingenImage1 from "../assets/bauenimbestandgerlingen/Pers.jpg";
import GerlingenImage2 from "../assets/bauenimbestandgerlingen/Pers3.jpg";
import GerlingenVorher from "../assets/bauenimbestandgerlingen/vorher.png";
import GerlingenNord from "../assets/bauenimbestandgerlingen/04AnsichtNord.jpg";
import GerlingenOst from "../assets/bauenimbestandgerlingen/04AnsichtOst.jpg";
import GerlingenSüd from "../assets/bauenimbestandgerlingen/04AnsichtSüd.jpg";

import PfuhlImage from "../assets/PfuhlPlan3D.png";
import PfuhlVorher2 from "../assets/bauenimbestandpfuhl/vorher2.png";
import PfuhlVorher from "../assets/bauenimbestandpfuhl/vorher.png";

import HolzhausenImage from "../assets/image-001.png";
import HolzhausenImage1 from "../assets/bauenimbestandanwesenholzhausen/100-0033_IMGKopie.jpeg";
import HolzhausenImage2 from "../assets/bauenimbestandanwesenholzhausen/100-0034_IMGKopierenKopie.jpeg";
import HolzhausenImage3 from "../assets/bauenimbestandanwesenholzhausen/100-0041_IMGKopierenKopie.jpeg";
import HolzhausenImage4 from "../assets/bauenimbestandanwesenholzhausen/100-0046_IMGKopie.jpeg";

import SontheimImage from "../assets/AnsichtSued:West(1).jpeg";
import SontheimImage1 from "../assets/bauenimbestandsontheim/AnsichtSüdWest.jpeg";
import SontheimImage2 from "../assets/bauenimbestandsontheim/AnsichtSüdWest1.jpeg";
import SontheimImage3 from "../assets/bauenimbestandsontheim/AnsichtNordOst.jpeg";
import SontheimImage4 from "../assets/bauenimbestandsontheim/AnsichtNordOst1.jpeg";
import SontheimVorher from "../assets/bauenimbestandsontheim/Pers.jpeg";

import SalachImage from "../assets/bauenimbestandsalach/Ansicht.jpg";
import SalachImage1 from "../assets/bauenimbestandsalach/Ansicht1.jpg";
import SalachVorher from "../assets/bauenimbestandsalach/bestandweber.jpeg";

const bauenimbestand = [
  {
    id: 11,
    title: "Aufstockung mit Ausbau des Scheunenteils, Geislingen",
    place: "Geislingen",
    duration: "2022-2023",
    description:
      "Aufstockung eines einfachen, ehemals landwirtschaftlichen Gebäudes in Holzständerbauweise mit Ausbau des Scheunenteils zu einer zusätzlichen Wohneinheit.",
    type: "Privater Bauherr",
    image: GeislingenImage,
    imageAlt: "",
    href: "11",
    gallery: [
      { url: GeislingenImage },
      { url: GeislingenVorher, before: true },
    ],
  },
  {
    id: 12,
    title: "Mehrfamilienhaus Aufstockung Nersingen",
    place: "Nersingen, Ortsteil Leibi",
    duration: "2019-2020",
    description:
      "Aufstockung in Holzständerbauweise eines Mehrfamilienhauses zu einer zusätzlichen Wohneinheit auf 2 Ebenen.",
    type: "Privater Bauherr",
    image: LeibiImage1,
    imageAlt: "",
    href: "12",
    gallery: [
      { url: LeibiImage1 },
      { url: LeibiImage2 },
      { url: LeibiVorher, before: true },
    ],
  },
  {
    id: 13,
    title: "Sanierung des Bestandsgebäudes und Anbau München-Forstenried",
    place: "München-Forstenried",
    duration: "2003",
    description:
      "Sanierung des Bestandsgebäudes und Anbau in Holzständerbauweise, Aussenwände Lärchenrhombusstäbe",
    type: "Privater Bauherr",
    image: ForstenriedImage,
    imageAlt: "",
    href: "13",
    gallery: [
      { url: ForstenriedImage },
      { url: ForstenriedVorher, before: true },
      { url: ForstenriedImage2 },
    ],
  },
  {
    id: 14,
    title: "Sanierung des Bestandsgebäudes und Ausbau in Gerlingen",
    place: "Gerlingen, Landkreis Ludwigsburg",
    duration: "Baubeginn vorausichtlich 2024",
    description:
      "Sanierung des Bestandsgebäudes und Ausbau zu 18 Micro-Appartements",
    type: "Immobilienunternehmen",
    image: GerlingenImage,
    imageAlt: "",
    href: "14",
    gallery: [
      { url: GerlingenImage },
      { url: GerlingenImage1 },
      { url: GerlingenImage2 },
      { url: GerlingenVorher, before: true },
      { url: GerlingenNord },
      { url: GerlingenOst },
      { url: GerlingenSüd },
    ],
  },
  {
    id: 15,
    title: "Sanierung des Bestandsgebäudes 5 Wohneinheiten, Pfuhl",
    place: "Neu-Ulm, Ortsteil Pfuhl",
    duration: "Baubeginn 2024",
    description:
      "Sanierung des Bestandsgebäudes mit 2 Wohneinheiten und Anbau von 3 Wohneinheiten",
    type: "Immobilienunternehmen",
    image: PfuhlImage,
    imageAlt: "",
    href: "15",
    gallery: [
      { url: PfuhlImage },
      { url: PfuhlVorher2, before: true },
      { url: PfuhlVorher, before: true },
    ],
  },
  {
    id: 16,
    title:
      "Sanierung eines landwirtschaftlichen Anwesens, Holzhausen Landkreis München",
    place: "Holzhausen, Straßlach-Dingharting, Landkreis München",
    duration: "2001",
    description:
      "Sanierung eines landwirtschaftlichen Anwesens mit Teilabriss und Wiederaufbau, Wohnen mit Pferden",
    type: "Privater Bauherr",
    image: HolzhausenImage,
    imageAlt: "",
    href: "16",
    gallery: [
      { url: HolzhausenImage },
      { url: HolzhausenImage1 },
      { url: HolzhausenImage2 },
      { url: HolzhausenImage3 },
      { url: HolzhausenImage4 },
    ],
  },
  {
    id: 17,
    title: "Sanierung eines landwirtschaftlichen Gebäudes Sontheim",
    place: "Sontheim, Ostalbkreis",
    duration: "Baubeginn 2024",
    description:
      "Sanierung eines landwirtschaftlichen Gebäudes mit Teilabriss und Wiederaufbau zu insgesamt 5 Wohneinheiten Ausbau des Scheunenteils zu 1 Wohneinheit im Obergeschoss und Nebenräumen im Erdgeschoss.",
    type: "Privater Bauherr",
    image: SontheimImage,
    imageAlt: "",
    href: "17",
    gallery: [
      { url: SontheimImage1 },
      { url: SontheimImage2 },
      { url: SontheimImage3 },
      { url: SontheimImage4 },
      { url: SontheimVorher, before: true },
    ],
  },
  {
    id: 18,
    title: "Mehrfamilienhaus Salach",
    place: "Salach, Kreis Göppingen",
    duration: "Baubeginn 2024",
    description:
      "Energetische Sanierung des Bestandsgebäude und Erweiterung von 2 zu 3 Wohneinheiten",
    type: "Privater Bauherr",
    image: SalachImage,
    imageAlt: "",
    href: "18",
    gallery: [
      { url: SalachImage },
      { url: SalachImage1 },
      { url: SalachVorher, before: true },
    ],
  },
];

export default bauenimbestand;
