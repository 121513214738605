import Beigarten from "../assets/Hang-unten.jpg";
import BeigartenSub1 from "../assets/EinfamilienhausinBeigartenbeiMünchen/Hang-unten.jpg";
import BeigartenSub2 from "../assets/EinfamilienhausinBeigartenbeiMünchen/Galerie.jpg";
import BeigartenSub3 from "../assets/EinfamilienhausinBeigartenbeiMünchen/mitKapelle.jpg";
import Ottenbach from "../assets/20230819_094801.jpg";
import OttenbachSub1 from "../assets/EinfamilienhausOttenbach/20230819_094801.jpg";
import OttenbachSub2 from "../assets/EinfamilienhausOttenbach/IMG_0454.jpg";
import Postdam from "../assets/Potsdam-2.jpg";
import PostdamSub1 from "../assets/EinfamilienhausPostdam/Potsdam-2.jpg";
import PostdamSub2 from "../assets/EinfamilienhausPostdam/Potsdam-6.jpeg";
import NeuUlm from "../assets/AllteSatdtgärtnerei2.jpg";
import NeuUlm1 from "../assets/EinfamilienhausNeuUlm/AllteSatdtgärtnerei-2.jpg";
import NeuUlm2 from "../assets//EinfamilienhausNeuUlm/AllteSatdtgärtnerei-2.jpg";

const einfamilien = [
  {
    id: 1,
    title: "Einfamilienhaus, Beigarten",
    place: "Beigarten, Straßlach-Dingharting, Landkreis München",
    duration: "2004",
    description:
      "Neubau eines Einfamilienhauses mit freien Blick über das Isartal Holzständerbauweise, Aussenwände Lärchenrhombusstäbe",
    type: "Privater Bauherr",
    image: Beigarten,
    imageAlt: "",
    href: "1",
    gallery: [
      { url: BeigartenSub1 },
      { url: BeigartenSub2 },
      { url: BeigartenSub3 },
    ],
  },
  {
    id: 2,
    title: "Neubau Einfamilienhaus, Ottenbach",
    place: "Ottenbach, Landkreis Göppingen",
    duration: "2020",
    description: "Neubau eines Einfamilienhauses in Nassivbauweise",
    type: "Privater Bauherr",
    image: Ottenbach,
    imageAlt: "",
    href: "2",
    gallery: [{ url: OttenbachSub1 }, { url: OttenbachSub2 }],
  },
  {
    id: 3,
    title: "Neubau Einfamilienhaus, Potsdam",
    place: "Potsdam",
    duration: "",
    description:
      "Neubau eines Einfamilienhauses mit Einliegerwohnung am Havelufer Massivbauweise mit Pfahgründung",
    type: "Privater Bauherr",
    image: Postdam,
    imageAlt: "",
    href: "3",
    gallery: [{ url: PostdamSub1 }, { url: PostdamSub2 }],
  },
  {
    id: 4,
    title: "Neubau Einfamilienhaus, Neu-Ulm",
    place: "Neu-Ulm",
    duration: "2015",
    description: "Neubau eines Einfamilienhauses Massivbauweise",
    type: "Privater Bauherr",
    image: NeuUlm,
    imageAlt: "",
    href: "4",
    gallery: [{ url: NeuUlm1 }, { url: NeuUlm2 }],
  },
];

export default einfamilien;
