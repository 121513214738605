"use client";

import { useRef, useEffect, createRef } from "react";
import { Footer, Navigation } from "../organisms";
import imagesLoaded from "imagesloaded";
import AOS from "aos";
import "aos/dist/aos.css";

const preloadImages = (selector: string) => {
  return new Promise((resolve) => {
    imagesLoaded(
      document.querySelectorAll(selector),
      { background: true },
      resolve
    );
  });
};

export default function LayoutWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <html lang="en">
      <body>
        <Navigation
          links={[
            { name: "Projekte", path: "/projectcategories" },
            { name: "Leistungen", path: "/services" },
            { name: "Gutachter", path: "/gutachter" },
            { name: "Büro", path: "/office" },
            { name: "Kontakt", path: "/contact" },
          ]}
        />
        <main className="flex-row mt-4 overflow-hidden flex-1">{children}</main>
        <Footer />
      </body>
    </html>
  );
}
