import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

import { Link, useLocation } from "react-router-dom";

interface ProjectCardProps {
  title?: string;
  image?: string;
  imageAlt?: string;
  href?: string;
  show?: boolean;
  id?: number;
  fixedWidth?: number;
  fixedHeight?: number;
  undersix?: boolean;
  hasonlytwo?: boolean;
}

const ProjectCard = (props: ProjectCardProps) => {
  const {
    title,
    image,
    imageAlt,
    href,
    show,
    id,
    fixedWidth,
    fixedHeight,
    undersix,
    hasonlytwo,
  } = props;

  const path = useLocation();

  const extractText = (pathname: string) => {
    const startIndex =
      pathname.indexOf("/projectcategories/") + "/projectcategories/".length;
    return pathname.substring(startIndex);
  };

  const isMultiFamilyOrBuildingInStock =
    path.pathname.includes("mehrfamilienhaeuser") ||
    path.pathname.includes("bauen%20im%20bestand");

  const containerWidth = isMultiFamilyOrBuildingInStock ? 250 : fixedWidth;
  const containerHeight = isMultiFamilyOrBuildingInStock ? 250 : fixedHeight;
  const textPixel = hasonlytwo
    ? "text-[14px]"
    : path.pathname === "/mehrfamilienhaeuser" ||
      path.pathname === "/bauen im bestand"
    ? "text-[14px]"
    : "text-[19px]";

  /**
   * 
      href={{
        pathname: `/projectcategories/${extractText(path.pathname)}/${href?.toLocaleLowerCase()}`,
        query: {title: title, id: id},
      }}
   */

  useEffect(() => {
    console.log("hasonlytwo", hasonlytwo);
  }, [hasonlytwo]);

  return (
    <Link
      className={`relative ${
        hasonlytwo
          ? "max-w-[600px] max-[600px]:!w-[100%] max-[600px]:!max-w-[100%] max-[600px]:!flex-none"
          : undersix
          ? "project-three max-[600px]:!w-[100%] max-[600px]:!max-w-[100%] max-[600px]:!flex-none"
          : "project-and max-[600px]:!w-[100%] max-[600px]:!max-w-[100%] max-[600px]:!flex-none"
      } overflow-hidden transition hover:scale-110 ease-in duration-300 transform !z-[999] ${
        show ? "" : "translate-y-16 opacity-0"
      }`}
      to={{
        pathname: `/projectcategories/${extractText(
          path.pathname
        )}/${href?.toLocaleLowerCase()}`,
        search: `?title=${title}&id=${id?.toString()}`,
      }}
      // Anpassung der Breite und Höhe des Containers
    >
      <div className="project-category">
        <img
          className="object-cover !h-[70%] !w-[100%]"
          src={image!}
          alt={imageAlt!}
          style={{ width: fixedWidth, height: fixedHeight }}
          width={fixedWidth}
          height={fixedHeight}
        />
        <div className="p-10 bg-[#676767] h-[30%] flex justify-center items-center">
          <h5
            style={{ fontSize: "1vw" }}
            className={`text-[#fff] max-[600px]:!text-[3vw] max-[600px]:!text-center text-[1vw]`}
          >
            {title?.toLocaleLowerCase()}
          </h5>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
