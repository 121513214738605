"use client";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

interface ProjectCategoryProps {
  title?: string;
  image?: string;
  imageAlt?: string;
  href?: string;
  show?: boolean;
  id?: number;
  fixedWidth?: number;
  fixedHeight?: number;
  undersix?: boolean;
}

const ProjectCategory = (props: ProjectCategoryProps) => {
  const {
    title,
    image,
    imageAlt,
    href,
    show,
    id,
    fixedWidth,
    fixedHeight,
    undersix,
  } = props;

  console.log("id =>", undersix);

  /**
   * 
      href={{
        pathname: `/projectcategories/${href?.toLocaleLowerCase()}`,
        query: {title: title, id: id?.toString()},
      }}
   */

  return (
    <Link
      className={`relative cursor-pointer ${
        undersix
          ? "project-three max-[600px]:!w-[100%] max-[600px]:!max-w-[100%] max-[600px]:!flex-none"
          : "projects-category max-[600px]:!w-[100%] max-[600px]:!max-w-[100%] max-[600px]:!flex-none"
      } overflow-hidden transition hover:scale-110 ease-in duration-300 transform ${
        show ? "" : "translate-y-16 opacity-0"
      }`}
      to={{
        pathname: `/projectcategories/${href}`,
        search: `?title=${title}&id=${id?.toString()}`,
      }}
    >
      <div className={`project-category`}>
        <img
          className="!object-cover !h-[70%]"
          src={image!}
          alt={imageAlt!}
          width={fixedWidth}
          height={fixedHeight}
        />
        <div className="p-10 bg-[#676767] h-[30%] flex justify-center items-center">
          <h5 className="text-[#fff] text-[19px]">{title}</h5>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCategory;
