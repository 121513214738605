import React from "react";
import Solaris2 from "../assets/Solaris2.png";
import P1 from "../assets/P1.png";

const entwicklung = [
  {
    id: 31,
    title: "Prototyp eines energetisch hocheffizienten Einfamilienhauses",
    place: "",
    duration: "",
    description:
      "Prototyp eines energetisch hocheffizienten Einfamilienhauses in Holzständerbauweise",
    type: "Johann Lechner GmbH, Buchbach, Landkreis Mühldorf am Inn",
    image: Solaris2,
    imageAlt: "",
    href: "31",
    gallery: [{ url: Solaris2 }],
  },
  {
    id: 32,
    title: "Städtebaulicher Entwurf für Blaubeuren",
    place: "",
    duration: "",
    description:
      "Städtebaulicher Entwurf für Blaubeuren, Stadtteil Gerhausen, „Beim Schillerstein“",
    type: "",
    image: P1,
    imageAlt: "",
    href: "32",
    gallery: [{ url: P1 }],
  },
];

export { entwicklung };
