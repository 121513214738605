"use client";

import { Dispatch, SetStateAction } from "react";

interface NavigationLinkProps {
  text: string;
  href: string;
  active: boolean;
  color: string;
  fontBold?: boolean;
  underline?: boolean;
  lowercase?: boolean;
  isMobile?: boolean;
  setMenuOpen?: Dispatch<SetStateAction<boolean>>;
}

const NavigationLink = (props: NavigationLinkProps) => {
  const {
    text,
    href,
    active,
    color,
    fontBold,
    underline,
    lowercase,
    isMobile = false,
    setMenuOpen,
  } = props;

  return (
    <a
      onClick={() => {
        isMobile && setMenuOpen && setMenuOpen(false);
      }}
      className={`text-[16px] ${color} ${lowercase && "!lowercase"} ${
        fontBold ? fontBold : ""
      } ${
        underline && active ? "border-b-2 border-black pb-5" : ""
      } cursor-pointer no-underline`}
      href={href}
    >
      {text}
    </a>
  );
};

export default NavigationLink;
