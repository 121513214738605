import { ILinks } from "../../../interfaces";
import { NavigationLinks, NavigationLinksMobile } from "../../molecules";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react"; // Import des useEffect-Hooks
import Logo from "../../../assets/LogoStuber.png";

interface NavigationProps {
  links: Array<ILinks>;
}

const Navigation = (props: NavigationProps) => {
  const { links } = props;
  const [isScrolled, setIsScrolled] = useState(false); // Zustand für den Scroll-Status
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 1) {
        setIsScrolled(true); // Wenn gescrollt wird, wird der Zustand auf true gesetzt
      } else {
        setIsScrolled(false); // Wenn nach oben gescrollt wird, wird der Zustand auf false gesetzt
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // useEffect nur einmal beim ersten Rendern aufrufen

  return (
    <nav
      className={`data-scroll-trigger="enter" ${
        isScrolled
          ? "sticky top-0 bg-white shadow-lg z-50 p-4 max-[600px]:flex-row max-[600px]:justify-around max-[600px]:items-center"
          : "p-4 max-[600px]:flex-row max-[600px]:justify-around max-[600px]:items-center"
      } transition duration-200 w-full flex justify-center items-center flex-col`}
    >
      <a onClick={() => navigate("/")} className="no-underline cursor-pointer">
        <img
          className={`grid-item-media`}
          src={Logo}
          alt="Logo Andreas Stuber"
          width={216}
          height={143}
        />
      </a>
      <NavigationLinks
        links={links}
        lowercase={true}
        customClass="max-[600px]:hidden flex"
      />
      <NavigationLinksMobile
        links={links}
        lowercase={true}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        customClass="max-[600px]:flex hidden"
      />
      <div
        id="hamburger"
        onClick={() => {
          setMenuOpen(true);
        }}
        className="max-[600px]:flex max-[600px]:flex-col max-[600px]:gap-1 hidden"
      >
        <div className="w-[30px] h-[5px] bg-[#676767]"></div>
        <div className="w-[30px] h-[5px] bg-[#676767]"></div>
        <div className="w-[30px] h-[5px] bg-[#676767]"></div>
      </div>
    </nav>
  );
};

export default Navigation;
